import React from 'react';

import EventList from 'components/News/Event/List';
import PageTitleBar from 'components/misc/PageTitleBar';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '새메뉴', link: '/news/new/' },
  { name: '이벤트', link: '/news/event/' },
  { name: '공지사항', link: '/news/notice/' },
];

const EventListPage = () => (
  <div>
    <PageTitleBar title="Event" backgroundImage={require('../../../assets/images/banner/news.jpg')} />
    <TabBar index={1} data={tabBarMenus} />
    <EventList />
  </div>
);

export default EventListPage;

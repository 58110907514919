import React from 'react';
import PropTypes from 'prop-types';
import { Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from './Item.module.scss';
import ScaleImageCell from '../../../misc/ScaleImageCell';
import ArticleTitle from '../../../misc/ArticleTitle';

const cx = classNames.bind(styles);

const Item = ({ item }) => {
  const { id, title, thumb_image, start_date, end_date } = item;

  const fromDate = moment(start_date).format('YYYY.MM.DD');
  const toDate = moment(end_date).format('YYYY.MM.DD');
  const label = moment().isAfter(end_date, 'day') ? '종료' : '진행 중';

  return (
    <ScreenClassRender render={(screenClass) => {
      const date = ['xs', 'md'].includes(screenClass) ? `${toDate} 까지` : `${fromDate} ~ ${toDate}`;
      
      return (
        <Col xs={6} md={4} lg={3} className={cx('column')}>
          <div className={cx('cell')}>
            <div className={cx('label')}>
              <div className={cx('text')}>{label}</div>
            </div>
            <Link to={`/news/event/view/?${id}`}>
              {thumb_image !== null && (
                <ScaleImageCell image={thumb_image.url} />
              )}
              {thumb_image === null && (
                <ScaleImageCell image="https://dummyimage.com/270x270/000/fff" />
              )}
              <ArticleTitle title={title} date={date} />
            </Link>
          </div>
        </Col>
      );
    }} />
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Item;
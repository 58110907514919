import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import Item from './Item';
import { eventListActions } from '../../../../actions';

const cx = classNames.bind(styles);

class List extends Component {
  
  componentDidMount() {
    if (this.props.eventList.data.length === 0) {
      this.props.actions.eventListActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.eventList;
    return !isProcessing && !failure;
  }

  render() {
    const { eventList } = this.props;

    return (
      <div className={cx('container')}>
        <Container>
          <Row>
            {eventList.data.map((event, i) => <Item key={String(event.id)} item={event} />)}
          </Row>
        </Container>
      </div>
    );
  }
}


/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { eventList } = state;
  return {
    eventList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      eventListActions: bindActionCreators(eventListActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
